$(document).ready(function(){
	$('.js-slider').owlCarousel({
		items: 1,
		loop: true,
		smartSpeed: 1000,
		mouseDrag: false,
		autoHeight: true
	});
	accordion();
	tabs();

	initPopups();

	autosize($('.autosize'));

	$('.form-item-holder select').styler();
	$('.bottom-row input[type="file"]').styler();

	$('.phone-input').mask('+7 (999) 999-99-99',{
        placeholder: '_'
    });

	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('.fixed').outerHeight();

	$(window).scroll(function(event){
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		if(Math.abs(lastScrollTop - st) <= delta)
			return;

		if (st > lastScrollTop && st > navbarHeight){
			$('.fixed').attr('class', 'fixed nav-up');
		} else {
			if(st + $(window).height() < $(document).height() && st > 100) {
				$('.fixed').attr('class', 'fixed nav-down');
			}
		}

		if(st < 100) {
			$('.fixed').attr('class', 'fixed onTop');
		}

		lastScrollTop = st;
	}

	$('.tooltip').qtip({
		position: {
			viewport: $(window)
		},
		show: {
	        effect: function(offset) {
	            $(this).fadeIn(200);
	        }
	    },
		hide: {
	        effect: function(offset) {
	            $(this).fadeOut(200);
	        }
	    }
	});

});

function accordion(){
	var opener = $('.js-drop-opener'),
		dropBox = $('.js-drop-opened');

	opener.on('click', function(){
		$(this).next(dropBox).slideToggle();
		$(this).toggleClass('active');
	})
}

function tabs() {
	var tabLink = $('.js-tab-menu a'),
		tabContent = $('.js-tab-content');

	tabLink.on('click', function(e){
		e.preventDefault();
		tabContent.removeClass('active');
		$($(this).attr('href')).addClass('active');
		tabLink.removeClass('active');
		$(this).addClass('active');
	})
}

function initPopups() {
	var opener = $('.open-popup'),
		lightbox = $('.popup'),
		fader = $('.fader'),
		closer = $('.popup .close-popup'),
		id;

	lightbox.each(function(){
		var el = $(this);
		if(el.hasClass('show-me-onload')) {
			el.addClass('visible');
			fader.addClass('visible');
		} else {
			el.removeClass('visible');
		}
	});

	opener.on('click', function(e){
		e.preventDefault();
		if($(this).parents('.popup')) {
			$(this).parents('.popup').removeClass('visible');
		}
		id = $(this).attr('href').substr('1');
		lightbox.each(function(){
			var lightbox_box = $(this);
			if(id == $(this).attr('id')) {
				lightbox_box.addClass('visible');
				fader.addClass('visible');
				$('.page').addClass('blurred');
				$('body').addClass('popup-opened');
			}
		});
		return false;
	});

	function disappearLightbox() {

		lightbox.removeClass('visible');
		setTimeout(function(){
			if($('.page').attr('style')){
				$('body').addClass('open-menu');
			}
		}, 500);
		setTimeout(function(){
			fader.removeClass('visible');
			$('.page').removeClass('blurred');
			$('body').removeClass('popup-opened');
		}, 100)
	}

	function closeLightbox(element) {
		element.click(function(e){
			disappearLightbox();
			e.preventDefault();
			return false;
		})
	}

	closeLightbox(fader);
	closeLightbox(closer);

	$(window).keydown(function(eventObject){
		if (eventObject.which == 27){
			disappearLightbox()
		}
	});

}